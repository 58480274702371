import "./analytics.scss"
import Vue from 'vue/dist/vue.esm'
import AnalyticsApp from './analytics-app.vue'
import ActionCableVue from 'actioncable-vue'
import moment from "moment"

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
})

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("analytics"))
       .forEach((element) => {
         const analytics         = JSON.parse(element.dataset.analytics)
         const summary           = JSON.parse(element.dataset.summary)
         const channels          = JSON.parse(element.dataset.channels)
         const channel_id        = element.dataset.channelId
         const platforms         = JSON.parse(element.dataset.platforms)
         const platform_id       = element.dataset.platformId
         const media_plannings   = JSON.parse(element.dataset.mediaPlannings)
         const media_planning_id = element.dataset.mediaPlanningId
         const campaigns         = JSON.parse(element.dataset.campaigns)
         const campaign_id       = element.dataset.campaignId
         const customers         = JSON.parse(element.dataset.customers)
         const customer_types    = JSON.parse(element.dataset.customerTypes)
         const advertisers       = JSON.parse(element.dataset.advertisers)
         const turnover_groups   = JSON.parse(element.dataset.turnoverGroups)
         const customer_id       = element.dataset.customerId
         const customer_type_id  = element.dataset.customerTypeId
         const advertiser_id     = element.dataset.advertiserId
         const turnover_group_id = element.dataset.turnoverGroupId
         const users                    = JSON.parse(element.dataset.users)
         const manager_id        = element.dataset.managerId
         const teams                    = JSON.parse(element.dataset.teams)
         const team_id           = element.dataset.teamId
         let start_date
         if (element.dataset.startDate) {
           start_date = moment(JSON.parse(element.dataset.startDate)).format("YYYY-MM-DD")
         }
         let end_date
         if (element.dataset.endDate) {
           end_date = moment(JSON.parse(element.dataset.endDate)).format("YYYY-MM-DD")
         }
         const group_id                 = element.dataset.groupId
         const secondary_group_id       = element.dataset.secondaryGroupId
         const tertiary_group_id        = element.dataset.tertiaryGroupId
         const count_media_plannings    = element.dataset.countMediaPlannings === "true" ? 1 : 0
         const count_campaigns          = element.dataset.countCampaigns === "true" ? 1 : 0
         const show_commercial_insights = element.dataset.showCommercialInsights === "true" ? 1 : 0
         const show_cost_metrics        = element.dataset.showCostMetrics === "true" ? 1 : 0
         const show_performance_metrics = element.dataset.showPerformanceMetrics === "true" ? 1 : 0
         const analytics_info           = element.dataset.analyticsInfo

         new Vue({
                   data: {
                     analytics,
                     summary,
                     channels,
                     channel_id,
                     platforms,
                     platform_id,
                     media_plannings,
                     media_planning_id,
                     campaigns,
                     campaign_id,
                     customers,
                     customer_types,
                     advertisers,
                     turnover_groups,
                     customer_id,
                     customer_type_id,
                     advertiser_id,
                     turnover_group_id,
                     users,
                     manager_id,
                     teams,
                     team_id,
                     start_date,
                     end_date,
                     group_id,
                     secondary_group_id,
                     tertiary_group_id,
                     count_media_plannings,
                     count_campaigns,
                     show_commercial_insights,
                     show_cost_metrics,
                     show_performance_metrics,
                     analytics_info
                   },
                   template: "<AnalyticsApp :original_analytics='analytics' :original_summary='summary' :original_channels='channels' :original_channel_id='channel_id' :original_platforms='platforms' :original_platform_id='platform_id' :original_media_plannings='media_plannings' :original_media_planning_id='media_planning_id' :original_campaigns='campaigns' :original_campaign_id='campaign_id' :original_customers='customers' :original_customer_types='customer_types' :original_advertisers='advertisers' :original_turnover_groups='turnover_groups' :original_customer_id='customer_id' :original_customer_type_id='customer_type_id' :original_advertiser_id='advertiser_id' :original_turnover_group_id='turnover_group_id' :original_users='users' :original_manager_id='manager_id' :original_teams='teams' :original_team_id='team_id' :original_start_date='start_date' :original_end_date='end_date' :original_group_id='group_id' :original_secondary_group_id='secondary_group_id' :original_tertiary_group_id='tertiary_group_id' :original_count_media_plannings='count_media_plannings' :original_count_campaigns='count_campaigns' :original_show_cost_metrics='show_cost_metrics' :original_show_performance_metrics='show_performance_metrics' :analytics_info='analytics_info' />",
                   components: {
                     AnalyticsApp,
                   }
                 }).$mount(element)
       })
})
