<template>
  <div class="customers">
    <h1>
      Kunden
    </h1>

    <div class="customers--commands">
      <a @click="open_customer_dialog()" class="customers--button">
        <i class="fi-plus"></i>
        Neuer Kunde
      </a>
    </div>

    <table>
      <thead>
      <tr class="customers--th">
        <th>ID</th>
        <th>Titel</th>
        <th>Typ</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="customer in displayable_customers"
          @click="open_customer_dialog(customer)"
          class="customers--row">
        <td>{{ customer.id }}</td>
        <td>{{ customer.title }}</td>
        <td>{{ customer_type(customer) }}</td>
      </tr>
      </tbody>
    </table>

    <customer-dialog v-if="current_customer"
                     :customer="current_customer"
                     :customer_types="customer_types"
                     @closed_requested="current_customer = null">
    </customer-dialog>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./customers.scss";
import CustomerDialog from "../customer-dialog/customer-dialog-app"
import axios from "axios"

export default {
  name: "customers",
  props: {
    original_customers: {
      required: true,
    },
  },
  components: {
    CustomerDialog,
  },
  data() {
    return {
      customers: this.original_customers,
      customer_types: [],
      current_customer: null,
    }
  },
  methods: {
    open_customer_dialog(customer = null) {
      if (customer) {
        this.current_customer = Object.assign({}, customer)
      } else {
        this.current_customer = {
          id: null,
          title: null,
        }
      }
    },
    customer_type(customer) {
      const customer_type = this.customer_types.find(ct => {
        return ct.id === customer.type_id
      })
      if (customer_type) return customer_type.title
    },
  },
  computed: {
    displayable_customers() {
      return this.customers.filter(customer => {
        if (customer.deleted) return
        if (!customer.persisted) return
        return true
      }).sort((c1, c2) => {
        const name1 = c1.title.toLowerCase()
        const name2 = c2.title.toLowerCase()
        if (name1 < name2) {
          return -1
        } else {
          return 1
        }
      })
    }
  },
  channels: {
    CustomerRegisteredChannel: {
      received(customer_json) {
        console.log("CustomerRegisteredChannel received")
        const customer = JSON.parse(customer_json);
        this.customers.push(customer)
        this.$cable.subscribe({
                                channel: 'CustomerUpdatedChannel',
                                id: customer.id
                              });
        this.$cable.subscribe({
                                channel: 'CustomerDeletedChannel',
                                id: customer.id
                              });
      },
    },
    CustomerUpdatedChannel: {
      received(customer_json) {
        console.log("CustomerUpdatedChannel received")
        const received_customer = JSON.parse(customer_json);
        const customer          = this.customers.find(customer => {
          return customer.id === received_customer.id
        })
        if (!customer) return
        Object.assign(customer, received_customer);
        console.log("Customer updated", customer)
      },
    },
    CustomerDeletedChannel: {
      received(customer_json) {
        console.log("CustomerDeletedChannel received")
        const received_customer = JSON.parse(customer_json);
        const index             = this.customers.findIndex(customer => {
          return customer.id === received_customer.id
        })
        if (index === -1) return
        this.customers.splice(index, 1);
      },
    },
  },
  mounted() {
    axios({
            method: 'get',
            url: `/customer_types.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.customer_types = response.data
        })
        .catch(error => {
          console.log("ERROR", error.response.data)
        })
    this.$cable.subscribe({
                            channel: 'CustomerRegisteredChannel',
                            stream: "customer_registered",
                          })
    this.customers.forEach((customer => {
      this.$cable.subscribe({
                              channel: 'CustomerUpdatedChannel',
                              id: customer.id
                            });
      this.$cable.subscribe({
                              channel: 'CustomerDeletedChannel',
                              id: customer.id
                            });
    }))
  },
}
</script>
