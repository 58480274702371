<template>
  <div class="media-planning-tasks-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <h3>Neuer Task</h3>
        <form @submit.prevent="register_task" ref="form">
          <div class="media-planning-tasks-dialog--grid3">
            <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--shrink">
              <vue-ctk-date-time-picker hint="Fällig am"
                                        color="#36bbb1"
                                        button-color="#36bbb1"
                                        format="YYYY-MM-DD HH:mm"
                                        :minute-interval="60"
                                        :no-button-now="true"
                                        :no-clear-button="true"
                                        :disabled="disabled"
                                        v-model="task.due_at"/>
            </div>
            <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--auto">
              <input type="text" v-model="task.title" class="input-group-field"
                     :disabled="disabled">
            </div>
            <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--shrink">
              <button class="media-planning-tasks-dialog--button"
                      :class="disabled_new_button_class"
                      :disabled="disabled || task_incomplete">
                <i class="fi-save"></i>
              </button>
            </div>
          </div>
        </form>

        <h3 v-if="tasks.length">Tasks</h3>

        <div v-if="errors" class="callout alert">
          <h5>Error</h5>
          <p v-for="error in errors">{{ error }}</p>
          <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <fieldset v-for="task in sorted_tasks" :key="task.id" class="media-planning-tasks-dialog--grid5">
          <div class="media-planning-tasks-dialog--cell">
            <vue-ctk-date-time-picker hint="Fällig am"
                                      color="#36bbb1"
                                      button-color="#36bbb1"
                                      :disabled="disabled || !!task.done_at"
                                      v-model="task.due_at"
                                      format="YYYY-MM-DD HH:mm"
                                      :minute-interval="60"
                                      :no-button-now="true"
                                      :no-clear-button="true"
                                      :auto-close="true"
                                      :no-button="true"
                                      @input="reschedule_task(task)"
            />
          </div>
          <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--auto">
            <div class="input-group">
              <input v-model="task.title"
                     @input="note_change(task)"
                     @keyup.enter="rename_task(task)"
                     type="text"
                     class="input-group-field"
                     :disabled="disabled || !!task.done_at">
              <div v-if="task_changed(task)" class="input-group-button">
                <button @click.prevent="rename_task(task)"
                        class="button"
                        :title="!task.done_at && `Task umbenennen`"
                        :disabled="disabled || !!task.done_at"
                        :class="task.done_at && 'media-planning-tasks-dialog--button--disabled'"
                        :ref="`title_${task.id}`">
                  <span class="fi-save"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="media-planning-tasks-dialog--cell">
            <span v-for="assignee in task.assignees" class="media-plannings--manager-email">
              {{ assignee.email }}
              <i v-if="!task.done_at"
                 title="Manager entfernen"
                 class="fi-x media-planning-tasks-dialog--unassign-manager"
                 @click="unassign_manager(task, assignee.manager_id)"></i>
            </span>
          </div>
          <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--shrink">
            <select @change="(event) => {assign_manager(task, event)}">
              <option value="null">Manager hinzufügen</option>
              <option v-for="manager in remaining_managers(task)" :value="manager.id">{{ manager.email }}</option>
            </select>
          </div>
          <div class="media-planning-tasks-dialog--cell media-planning-tasks-dialog--cell--shrink">
            <button v-if="!!!task.done_at"
                    @click.prevent="do_task(task)"
                    title="Task erledigen"
                    :disabled="disabled"
                    class="media-planning-tasks-dialog--button">
              <i class="fi-check"></i>
            </button>
            <button v-else
                    @click.prevent="reactivate_task(task)"
                    title="Task reaktivieren"
                    :disabled="disabled"
                    class="media-planning-tasks-dialog--button">
              <i class="fi-refresh"></i>
            </button>

            <button @click.prevent="delete_task(task)"
                    title="Task löschen"
                    :disabled="disabled"
                    class="media-planning-tasks-dialog--button--alert"
                    :class="disabled_button_class">
              <i class="fi-x"></i>
            </button>
          </div>
        </fieldset>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./media-planning-tasks-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios";
import moment from "moment";
import VueCtkDateTimePicker from '../../lib/vue-ctk-date-time-picker.common'
import '../../lib/vue-ctk-date-time-picker.css'
import Vue from 'vue/dist/vue.esm'

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker)

export default {
  name: "media-planning-tasks-dialog",

  props: {
    media_planning: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },

  components: {
    BaseModal,
  },

  data() {
    return {
      tasks: [],
      task: {
        due_at: moment().add(1, 'days').startOf('day').add(9, 'hours').format(),
        title: "",
      },
      changed_tasks: [],
      managers: [],
      errors: null,
      is_loading: false,
    }
  },

  methods: {
    note_change(task) {
      if (!this.changed_tasks.includes(task.id)) {
        this.changed_tasks.push(task.id)
      }
    },

    task_changed(task) {
      return this.changed_tasks.includes(task.id)
    },

    register_task() {
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/register_task`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                due_at: this.task.due_at,
                title: this.task.title,
              },
            })
          .then(_response => {
            this.is_loading  = false
            this.task.due_at = moment().add(1, 'days').startOf('day').add(9, 'hours').format()
            this.task.title  = ""
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    do_task(task) {
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/do`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    reactivate_task(task) {
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/reactivate`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    reschedule_task(task) {
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/reschedule`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                due_at: task.due_at,
              },
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    rename_task(task) {
      if (!task.title) return
      if (!this.task_changed(task)) return
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/rename`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                title: task.title,
              },
            })
          .then(_response => {
            this.is_loading = false
            this.changed_tasks.splice(this.changed_tasks.indexOf(task.id), 1);
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    assign_manager(task, event) {
      if (this.disabled) return
      const manager_id = event.target.value
      if (!manager_id) return
      this.is_loading    = true
      this.errors        = null
      event.target.value = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/assign_manager`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                manager_id: manager_id,
              },
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    unassign_manager(task, manager_id) {
      if (this.disabled) return
      this.is_loading = true
      this.errors     = null

      axios({
              method: 'post',
              url: `/tasks/${task.id}/unassign_manager`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                manager_id: manager_id,
              },
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    delete_task(task) {
      if (this.disabled) return

      let confirmation = confirm("Willst du den Task wirklich löschen?");
      if (!confirmation) return

      this.is_loading = true
      this.errors     = null

      axios({
              method: 'delete',
              url: `/tasks/${task.id}/delete`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then(_response => {
            this.is_loading = false
          })
          .catch(error => {
            this.is_loading = false
            this.errors     = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data)
          })
    },

    remaining_managers(task) {
      return this.managers.filter(m => {
        return !task.assignees.map(a => a.manager_id).includes(m.id)
      })
    },

    close_dialog() {
      this.$emit("closed_requested")
    },
  },

  computed: {
    sorted_tasks() {
      return this.tasks.sort((t1, t2) => {
        if (t1.due_at < t2.due_at) {
          return -1
        } else {
          return 1
        }
      })
    },
    task_incomplete() {
      if (!this.task.title) return true
      return !this.task.due_at;
    },
    disabled_new_button_class() {
      if (this.disabled) return "media-planning-tasks-dialog--button--disabled"
      if (this.task_incomplete) return "media-planning-tasks-dialog--button--disabled"
      return "media-planning-tasks-dialog--button"
    },
    disabled_button_class() {
      if (this.disabled) return "media-planning-tasks-dialog--button--disabled"
      return "media-planning-tasks-dialog--button"
    },
    disabled() {
      return this.is_loading || this.is_blocked
    },
  },

  channels: {
    TaskRegisteredChannel: {
      received(task_json) {
        console.log("TaskRegisteredChannel received")
        const task = JSON.parse(task_json);
        this.tasks.push(task)
        this.$cable.subscribe({
                                channel: 'TaskUpdatedChannel',
                                id: task.id
                              });
        this.$cable.subscribe({
                                channel: 'TaskDeletedChannel',
                                id: task.id
                              });
      },
    },
    TaskUpdatedChannel: {
      received(json) {
        const received_task = JSON.parse(json);
        console.log("TaskUpdatedChannel received", received_task)
        let task
        task = this.tasks.find(task => task.id === received_task.id)
        if (!task) return
        Object.assign(task, received_task);
        console.log("Task updated", task)
      },
    },
    TaskDeletedChannel: {
      received(json) {
        console.log("TaskDeletedChannel received", json)
        const task  = JSON.parse(json);
        const index = this.tasks.findIndex(t => t.id === task.id)
        if (index === -1) return
        this.tasks.splice(index, 1);
      },
    },
  },

  mounted() {
    this.$cable.subscribe({
                            channel: 'TaskRegisteredChannel',
                            stream: "task_registered",
                          })

    axios({
            method: 'get',
            url: `/media_plannings/${this.media_planning.id}/tasks.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.tasks = response.data

          this.tasks.forEach(task => {
            console.log("Subscribe to TaskUpdatedChannel", task.id)
            this.$cable.subscribe({
                                    channel: 'TaskUpdatedChannel',
                                    id: task.id
                                  })
            this.$cable.subscribe({
                                    channel: 'TaskDeletedChannel',
                                    id: task.id
                                  })
          })
        })
        .catch(error => {
          console.log("ERROR", error.response.data)
        })

    axios({
            method: 'get',
            url: `/managers.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.managers = response.data
        })
        .catch(error => {
          console.log("ERROR", error.response.data)
        })
  },
}
</script>
