<template>
  <div class="analytics">
    <h1>Analytics</h1>

    <h4>Gruppierung</h4>

    <div class="analytics--grid7">
      <select @change="select_group($event)" class="analytics--cell" :disabled="is_loading">
        <option value="date" :selected="group_id === 'date'">Day</option>
        <option value="week" :selected="group_id === 'week'">Week</option>
        <option value="month" :selected="group_id === 'month'">Month</option>
        <option value="year" :selected="group_id === 'year'">Year</option>
      </select>

      <select @change="select_secondary_group($event)" class="analytics--cell" :disabled="is_loading">
        <option value="" :selected="secondary_group_id === 'date'">No grouping</option>
        <option value="channel_id" :selected="secondary_group_id === 'channel_id'">Channel</option>
        <option value="platform_id" :selected="secondary_group_id === 'platform_id'">Platform</option>
        <option value="manager_id" :selected="secondary_group_id === 'manager_id'">Manager</option>
        <option value="team_id" :selected="secondary_group_id === 'team_id'">Team</option>
        <option value="customer_id" :selected="secondary_group_id === 'customer_id'">Customer</option>
        <option value="customer_type_id" :selected="secondary_group_id === 'customer_type_id'">Customer type</option>
        <option value="advertiser_id" :selected="secondary_group_id === 'advertiser_id'">Advertiser</option>
        <option value="turnover_group_id" :selected="secondary_group_id === 'turnover_group_id'">Turnover group</option>
        <option value="media_planning_id" :selected="secondary_group_id === 'media_planning_id'">Media plan</option>
        <option value="campaign_id" :selected="secondary_group_id === 'campaign_id'">Campaign</option>
      </select>

      <select @change="select_tertiary_group($event)" class="analytics--cell" :disabled="is_loading">
        <option value="" :selected="tertiary_group_id === 'date'">No grouping</option>
        <option value="channel_id" :selected="tertiary_group_id === 'channel_id'">Channel</option>
        <option value="platform_id" :selected="tertiary_group_id === 'platform_id'">Platform</option>
        <option value="manager_id" :selected="tertiary_group_id === 'manager_id'">Manager</option>
        <option value="team_id" :selected="tertiary_group_id === 'team_id'">Team</option>
        <option value="customer_id" :selected="tertiary_group_id === 'customer_id'">Customer</option>
        <option value="customer_type_id" :selected="tertiary_group_id === 'customer_type_id'">Customer type</option>
        <option value="advertiser_id" :selected="tertiary_group_id === 'advertiser_id'">Advertiser</option>
        <option value="turnover_group_id" :selected="tertiary_group_id === 'turnover_group_id'">Turnover group</option>
        <option value="media_planning_id" :selected="tertiary_group_id === 'media_planning_id'">Media plan</option>
        <option value="campaign_id" :selected="tertiary_group_id === 'campaign_id'">Campaign</option>
      </select>

      <select @change="select_count_media_plannings($event)" class="analytics--cell" :disabled="is_loading">
        <option :value="1" :selected="count_media_plannings">w/ # media plans</option>
        <option :value="0" :selected="!count_media_plannings">w/o # media plans</option>
      </select>

      <select @change="select_count_campaigns($event)" class="analytics--cell" :disabled="is_loading">
        <option :value="1" :selected="count_campaigns">w/ # campaigns</option>
        <option :value="0" :selected="!count_campaigns">w/o # campaigns</option>
      </select>

      <select @change="select_show_cost_metrics($event)" class="analytics--cell" :disabled="is_loading">
        <option :value="1" :selected="show_cost_metrics">w/ cost metrics</option>
        <option :value="0" :selected="!show_cost_metrics">w/o cost metrics</option>
      </select>

      <select @change="select_show_performance_metrics($event)" class="analytics--cell" :disabled="is_loading">
        <option :value="1" :selected="show_performance_metrics">w/ performance metrics</option>
        <option :value="0" :selected="!show_performance_metrics">w/o performance metrics</option>
      </select>
    </div>

    <h4>Filter</h4>
    <div class="analytics--grid8">
      <select @change="select_channel($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All channels</option>
        <option v-for="channel in channels" :value=channel.id :selected="channel.id === channel_id">
          {{ channel.title }}
        </option>
      </select>

      <select @change="select_platform($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All platforms</option>
        <option v-for="platform in platforms" :value=platform.id :selected="platform.id === platform_id">
          {{ platform.title }}
        </option>
      </select>

      <select @change="select_manager($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All managers</option>
        <option v-for="manager in managers" :value=manager.id :selected="manager.id === manager_id">
          {{ manager.email }}
        </option>
      </select>

      <select @change="select_team($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All teams</option>
        <option v-for="team in teams" :value=team.id :selected="team.id === team_id">
          {{ team.title }}
        </option>
      </select>

      <select @change="select_customer($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All customers</option>
        <option v-for="customer in customers" :value="customer.id" :selected="customer.id === customer_id">
          {{ customer.title }}
        </option>
      </select>

      <select @change="select_customer_type($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All customer types</option>
        <option v-for="customer_type in customer_types"
                :value="customer_type.id"
                :selected="customer_type.id === customer_type_id">
          {{ customer_type.title }}
        </option>
      </select>

      <select @change="select_advertiser($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All advertisers</option>
        <option v-for="advertiser in advertisers" :value="advertiser.id" :selected="advertiser.id === advertiser_id">
          {{ advertiser.title }}
        </option>
      </select>

      <select @change="select_turnover_group($event)" class="analytics--cell" :disabled="is_loading">
        <option value="">All turnover groups</option>
        <option v-for="turnover_group in turnover_groups" :value="turnover_group.id"
                :selected="turnover_group.id === turnover_group_id">
          {{ turnover_group.title }}
        </option>
      </select>
    </div>

    <div class="analytics--grid2">
      <div class="analytics--cell" style="margin-left: 0">
        <div class="analytics--grid3">
          <div class="analytics--cell">
            <input type="date" id="start_start" name="start_date" :value="start_date" min="2022-01-01"
                   :disabled="is_loading"
                   @change="select_start_date($event)">
          </div>
          <div class="analytics--cell">
            <input type="date" id="end_start" name="end_date" :value="end_date" :min="start_date"
                   :disabled="is_loading"
                   @change="select_end_date($event)">
          </div>
          <div class="analytics--cell">
            <a @click="set_ytd" class="hollow button" title="Year to date">YTD</a>
            <a @click="set_mtd" class="hollow button" title="Month to date">MTD</a>
          </div>
        </div>
      </div>

      <div class="analytics--cell--auto" style="margin-left: 1.47058rem">
        <multiselect
            v-model="media_planning"
            :options="media_plannings"
            :close-on-select="true"
            :clear-on-select="false"
            :preserveSearch="true"
            placeholder="Choose media plan"
            :custom-label="media_planning_title"
            label="title"
            track-by="id"
            :allow-empty="true"
            :disabled="is_loading"
            :show-labels="false">
        </multiselect>
      </div>
    </div>

    <div>
      <button @click="user && user.role === 'admin' && (open_analytics_info = !open_analytics_info)"
              :disabled="is_loading" class="analytics--button" style="padding: 4px 5px 4px 5px; margin: 0 0 8px 0;">
        <i class="fi-info"></i>
      </button>
      <span v-if="!open_analytics_info" style="margin-left: 4px;">
        {{ analytics_info }}
      </span>
      <analytics-info v-if="open_analytics_info" :text="analytics_info" :on_update="(v) => analytics_info = v"
                      :is_blocked="is_loading" @closed_requested="open_analytics_info = false"></analytics-info>
    </div>

    <analytics-table :group_id="group_id"
                     :secondary_group_id="secondary_group_id"
                     :tertiary_group_id="tertiary_group_id"
                     :channel_id="channel_id"
                     :platform_id="platform_id"
                     :manager_id="manager_id"
                     :team_id="team_id"
                     :customer_id="customer_id"
                     :customer_type_id="customer_type_id"
                     :advertiser_id="advertiser_id"
                     :turnover_group_id="turnover_group_id"
                     :media_planning_id="media_planning_id"
                     :campaign_id="campaign_id"
                     :channels="channels"
                     :platforms="platforms"
                     :users="users"
                     :teams="teams"
                     :customers="customers"
                     :customer_types="customer_types"
                     :advertisers="advertisers"
                     :turnover_groups="turnover_groups"
                     :media_plannings="media_plannings"
                     :campaigns="campaigns"
                     :count_media_plannings="count_media_plannings"
                     :count_campaigns="count_campaigns"
                     :show_commercial_insights="true"
                     :show_cost_metrics="show_cost_metrics"
                     :show_performance_metrics="show_performance_metrics"
                     :analytics="sorted_analytics"
                     :summary="summary">
    </analytics-table>

    <a v-if="sorted_analytics.length" :href="report_download_path"
       class="analytics--button">
      <i class="fi-download"></i>
      Download Analytics
    </a>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./analytics.scss"
import AnalyticsTable from "../analytics-table/analytics-table-app"
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import axios from 'axios'
import AnalyticsInfo from "../analytics-info/analytics-info-app.vue";

export default {
  name:       "analytics",
  props:      {
    original_analytics:                {
      required: true,
    },
    original_summary:                  {
      required: true,
    },
    original_channels:                 {
      required: true,
    },
    original_channel_id:               {
      required: true,
    },
    original_platforms:                {
      required: true,
    },
    original_platform_id:              {
      required: true,
    },
    original_media_plannings:          {
      required: true,
    },
    original_media_planning_id:        {
      required: true,
    },
    original_campaigns:                {
      required: true,
    },
    original_campaign_id:              {
      required: true,
    },
    original_customers:                {
      required: true,
    },
    original_customer_types:           {
      required: true,
    },
    original_advertisers:              {
      required: true,
    },
    original_turnover_groups:          {
      required: true,
    },
    original_customer_id:              {
      required: true,
    },
    original_customer_type_id:         {
      required: true,
    },
    original_advertiser_id:            {
      required: true,
    },
    original_turnover_group_id:        {
      required: true,
    },
    original_users:                    {
      required: true,
    },
    original_manager_id:               {
      required: true,
    },
    original_teams:                    {
      required: true,
    },
    original_team_id:                  {
      required: true,
    },
    original_start_date:               {
      required: true,
    },
    original_end_date:                 {
      required: true,
    },
    original_group_id:                 {
      required: true,
    },
    original_secondary_group_id:       {
      required: true,
    },
    original_tertiary_group_id:        {
      required: true,
    },
    original_count_media_plannings:    {
      required: true,
    },
    original_count_campaigns:          {
      required: true,
    },
    original_show_cost_metrics:        {
      required: true,
    },
    original_show_performance_metrics: {
      required: true,
    },
    analytics_info:                    {
      required: true,
    }
  },
  components: {
    Multiselect,
    AnalyticsTable,
    AnalyticsInfo,
  },
  data() {
    return {
      analytics:                this.original_analytics,
      summary:                  this.original_summary,
      channels:                 this.original_channels,
      channel_id:               this.original_channel_id,
      platforms:                this.original_platforms,
      platform_id:              this.original_platform_id,
      media_plannings:          this.original_media_plannings,
      media_planning_id:        this.original_media_planning_id,
      media_planning:           this.original_media_plannings.find(mp => mp.id === this.original_media_planning_id),
      campaigns:                this.original_campaigns,
      campaign_id:              this.original_campaign_id,
      customers:                this.original_customers,
      customer_types:           this.original_customer_types,
      advertisers:              this.original_advertisers,
      turnover_groups:          this.original_turnover_groups,
      customer_id:              this.original_customer_id,
      customer_type_id:         this.original_customer_type_id,
      advertiser_id:            this.original_advertiser_id,
      turnover_group_id:        this.original_turnover_group_id,
      users:                    this.original_users,
      manager_id:               this.original_manager_id,
      teams:                    this.original_teams,
      team_id:                  this.original_team_id,
      start_date:               this.original_start_date,
      end_date:                 this.original_end_date,
      group_id:                 this.original_group_id,
      secondary_group_id:       this.original_secondary_group_id,
      tertiary_group_id:        this.original_tertiary_group_id,
      count_media_plannings:    this.original_count_media_plannings,
      count_campaigns:          this.original_count_campaigns,
      show_cost_metrics:        this.original_show_cost_metrics,
      show_performance_metrics: this.original_show_performance_metrics,
      open_analytics_info:      false,
      is_loading:               false,
      cancel_token_source:      null,
      user:                     null,
    }
  },
  methods:  {
    select_group(event) {
      this.group_id = event.target.value
      this.fetch_analytics()
    },
    select_secondary_group(event) {
      this.secondary_group_id = event.target.value
      this.fetch_analytics()
    },
    select_tertiary_group(event) {
      this.tertiary_group_id = event.target.value
      this.fetch_analytics()
    },
    select_count_media_plannings(event) {
      this.count_media_plannings = parseInt(event.target.value)
      this.fetch_analytics()
    },
    select_count_campaigns(event) {
      this.count_campaigns = parseInt(event.target.value)
      this.fetch_analytics()
    },
    select_show_cost_metrics(event) {
      this.show_cost_metrics = parseInt(event.target.value)
      if (this.show_cost_metrics && this.secondary_group_id !== "channel_id" && this.tertiary_group_id !== "channel_id") {
        if (!this.secondary_group_id) {
          this.secondary_group_id = "channel_id"
        } else if (!this.tertiary_group_id) {
          this.tertiary_group_id = "channel_id"
        }
      }
      this.fetch_analytics()
    },
    select_show_performance_metrics(event) {
      this.show_performance_metrics = parseInt(event.target.value)
      if (this.show_performance_metrics && this.secondary_group_id !== "channel_id" && this.tertiary_group_id !== "channel_id") {
        if (!this.secondary_group_id) {
          this.secondary_group_id = "channel_id"
        } else if (!this.tertiary_group_id) {
          this.tertiary_group_id = "channel_id"
        }
      }
      this.fetch_analytics()
    },
    select_start_date(event) {
      this.start_date = event.target.value
      this.fetch_analytics()
    },
    select_end_date(event) {
      this.end_date = event.target.value
      this.fetch_analytics()
    },
    select_channel(event) {
      this.channel_id = event.target.value
      this.fetch_analytics()
    },
    select_platform(event) {
      this.platform_id = event.target.value
      this.fetch_analytics()
    },
    select_manager(event) {
      this.manager_id = event.target.value
      this.fetch_analytics()
    },
    select_team(event) {
      this.team_id = event.target.value
      this.fetch_analytics()
    },
    select_customer(event) {
      this.customer_id = event.target.value
      this.fetch_analytics()
    },
    select_customer_type(event) {
      this.customer_type_id = event.target.value
      this.fetch_analytics()
    },
    select_advertiser(event) {
      this.advertiser_id = event.target.value
      this.fetch_analytics()
    },
    select_turnover_group(event) {
      this.turnover_group_id = event.target.value
      this.fetch_analytics()
    },
    set_ytd() {
      const now = new Date()
      this.start_date = `${now.getFullYear()}-01-01`
      this.end_date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
      this.fetch_analytics()
    },
    set_mtd() {
      const now = new Date()
      this.start_date = `${now.getFullYear()}-${now.getMonth() + 1}-01`
      this.end_date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
      this.fetch_analytics()
    },
    fetch_analytics() {
      if (this.cancel_token_source) {
        this.cancel_token_source.cancel('New search replaces the existing to avoid race conditions.')
      }
      this.cancel_token_source = axios.CancelToken.source()
      this.is_loading = true

      history.pushState({}, '', `/analytics?${new URLSearchParams(this.search_params)}`)

      axios({
        method:      'get',
        url:         `/analytics`,
        headers:     { 'X-Requested-With': 'XMLHttpRequest' },
        params:      this.search_params,
        cancelToken: this.cancel_token_source.token,
      })
          .then(response => {
            this.analytics = response.data.analytics
            this.summary = response.data.summary
          })
          .catch(error => {
            console.log("ERROR", error)
          })
          .finally(() => {
            this.is_loading = false
            this.cancel_token_source = null
          })
    },
    media_planning_title({ title }) {
      return title
    },
  },
  computed: {
    sorted_analytics() {
      if (this.is_loading) return []

      return this.analytics.sort((a, b) => {
        if (this.group_id === "date") {
          return Date.parse(a._id.date) - Date.parse(b._id.date)
        }
        if (this.group_id === "week") {
          if (a._id.year < b._id.year) return -1
          if (a._id.year > b._id.year) return 1
          return a._id.week - b._id.week
        }
        if (this.group_id === "month") {
          if (a._id.year < b._id.year) return -1
          if (a._id.year > b._id.year) return 1
          return a._id.month - b._id.month
        }
        if (this.group_id === "year") {
          return a._id.year - b._id.year
        }
      })
    },
    managers() {
      return this.users.filter(user => user.role === "manager")
    },
    search_params() {
      const search_params = {
        start_date:                  this.start_date,
        end_date:                    this.end_date,
        include_commercial_insights: 1,
      }

      if (this.group_id) search_params["group_id"] = this.group_id
      if (this.secondary_group_id) search_params["secondary_group_id"] = this.secondary_group_id
      if (this.tertiary_group_id) search_params["tertiary_group_id"] = this.tertiary_group_id
      if (this.count_media_plannings) search_params["count_media_plannings"] = this.count_media_plannings
      if (this.count_campaigns) search_params["count_campaigns"] = this.count_campaigns
      if (this.show_cost_metrics) search_params["include_cost_metrics"] = 1
      if (this.show_performance_metrics) search_params["include_performance_metrics"] = 1
      if (this.media_planning_id) search_params["media_planning_id"] = this.media_planning_id
      if (this.campaign_id) search_params["campaign_id"] = this.campaign_id
      if (this.platform_id) search_params["platform_id"] = this.platform_id
      if (this.channel_id) search_params["channel_id"] = this.channel_id
      if (this.customer_id) search_params["customer_id"] = this.customer_id
      if (this.customer_type_id) search_params["customer_type_id"] = this.customer_type_id
      if (this.advertiser_id) search_params["advertiser_id"] = this.advertiser_id
      if (this.turnover_group_id) search_params["turnover_group_id"] = this.turnover_group_id
      if (this.manager_id) search_params["manager_id"] = this.manager_id
      if (this.team_id) search_params["team_id"] = this.team_id

      return search_params
    },
    report_download_path() {
      return `/analytics.xlsx?${new URLSearchParams(this.search_params)}`
    },
  },
  watch:    {
    "media_planning"() {
      if (this.media_planning) {
        this.media_planning_id = this.media_planning.id
      } else {
        this.media_planning_id = null
      }
      this.fetch_analytics()
    }
  },
  mounted() {
    axios({
      method:  'get',
      url:     `/users/current.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log("ERROR", error.response.data);
        });
  }
}
</script>
