<template>
  <div class="media-planning-notes-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <form @submit.prevent="save" ref="form">
          <h3>Notizen</h3>

          <fieldset>
            <textarea v-model="notes" cols="30" rows="15"></textarea>
          </fieldset>

          <div class="float-right">
            <button @click="close_dialog"
                    :disabled="is_loading"
                    class="media-planning-notes-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
            <button :disabled="is_loading || is_blocked"
                    class="media-planning-notes-dialog--button"
                    :class="disabled_button_class">
              <i class="fi-save"></i>
              Speichern
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./media-planning-notes-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios";

export default {
  name: "media-planning-notes-dialog",
  props: {
    media_planning: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      notes: this.media_planning.notes,
      is_loading: false,
    }
  },
  methods: {
    save() {
      if (this.is_loading) return
      this.is_loading = true

      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/update_notes`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                notes: this.notes,
              },
            })
          .then(_response => {
            this.is_loading = false
            this.close_dialog()
          })
          .catch(error => {
            this.is_loading = false
            console.log("ERROR", error.response.data)
          })
    },
    close_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    disabled_button_class() {
      if (this.is_loading) return "media-planning-notes-dialog--button--disabled"
      if (this.notes === this.media_planning.notes) return "media-planning-notes-dialog--button--disabled"
      return "media-planning-notes-dialog--button"
    },
  },
}
</script>
