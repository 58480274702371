<template>
  <span class="schedule-campaign-reporting">
    <button v-if="campaign.active_reporting && can_manage"
            @click.stop.prevent="edit = true"
            :class="disabled_button_class"
            class="schedule-campaign-reporting--button--small"
            title="Neues Reporting starten"
            :disabled="is_blocked">
      <i class="fi-refresh"></i>
      <span v-if="show_text">Neues Reporting starten</span>
    </button>

    <portal v-if="edit" to="base-modal">
      <base-modal v-if="edit" @closed_requested="edit = false">
        <h3>Neues Reporting starten</h3>

        <div v-if="errors" class="callout alert">
          <h5>Error</h5>
          <p v-for="error in errors">{{ error }}</p>
          <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <vue-ctk-date-time-picker id="campaign_start_date"
                                  format="YYYY-MM-DD"
                                  formatted="L"
                                  hint="Startdatum *"
                                  color="#36bbb1"
                                  button-color="#36bbb1"
                                  :only-date="true"
                                  :min-date="start_min_time"
                                  :max-date="start_max_time"
                                  :disabled="is_loading"
                                  v-model="start_date"/>
        <vue-ctk-date-time-picker id="campaign_end_date"
                                  format="YYYY-MM-DD"
                                  formatted="L"
                                  hint="Enddatum *"
                                  color="#36bbb1"
                                  button-color="#36bbb1"
                                  :only-date="true"
                                  :min-date="end_min_time"
                                  :max-date="end_max_time"
                                  :disabled="is_loading"
                                  v-model="end_date"/>

        <div class="schedule-campaign-reporting--integrations">
          {{ campaign.title }}
          <channel-icon :channel_title="campaign.channel_title"></channel-icon>

          <button v-for="integration in reporting_integrations"
                  @click.stop="remove_integration(integration.id)"
                  :disabled="is_loading"
                  :class="disabled_button_class"
                  class="schedule-campaign-reporting--label">
            {{ integration.platform_title }}
            <small>({{ integration.code }})</small>
            <i class="fi-x"></i>
          </button>
        </div>

        <div>
          <button
              @click="reset"
              :disabled="is_loading"
              :class="disabled_button_class"
              class="schedule-campaign-reporting--button--abort">
            <i class="fi-x"></i>
            Abbrechen
          </button>
          <button v-if="reporting_integrations.length"
                  @click.stop="schedule_reporting"
                  :disabled="is_loading"
                  :class="disabled_button_class"
                  class="schedule-campaign-reporting--button">
            <i class="fi-refresh"></i>
            Neues Reporting starten
          </button>
        </div>
      </base-modal>
    </portal>
  </span>
</template>

<script>
import "./schedule-campaign-reporting.scss";
import axios from "axios";
import moment from 'moment';
import Vue from 'vue/dist/vue.esm'
import VueCtkDateTimePicker from '../../lib/vue-ctk-date-time-picker.common';
import '../../lib/vue-ctk-date-time-picker.css';
import BaseModal from "../base-modal/base-modal-app"
import ChannelIcon from "../channel-icon/channel-icon-app"

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);

export default {
  name: "schedule-campaign-reporting",
  props: {
    media_planning: {
      required: true,
    },
    campaign: {
      required: true,
    },
    show_text: {
      required: false,
      default: false,
    },
    can_manage: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    BaseModal,
    ChannelIcon,
  },
  data() {
    let reporting_interval = Math.max(...this.campaign.integrations.map(integration => integration.default_reporting_interval))

    return {
      start_date: moment().subtract(reporting_interval, 'seconds').startOf('day').format('YYYY-MM-DD'),
      end_date: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
      reporting_integrations: this.build_reporting_integrations(),
      edit: false,
      is_loading: false,
      errors: null,
    }
  },
  methods: {
    build_reporting_integrations() {
      return this.campaign.integrations.map(integration => {
        return {
          id: integration.id,
          platform_id: integration.platform_id,
          platform_title: integration.platform_title,
          title: integration.title,
          code: integration.code,
        }
      })
    },
    remove_integration(integration_id) {
      this.reporting_integrations = this.reporting_integrations.filter(integration => {
        return integration.id !== integration_id
      })
    },
    reset() {
      this.edit                   = false
      this.reporting_integrations = this.build_reporting_integrations()
      this.errors                 = null
    },
    schedule_reporting() {
      if (this.is_loading) return
      this.is_loading = true;
      this.errors  = null

      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/schedule_reporting`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                campaign_id: this.campaign.id,
                start_date: this.start_date,
                end_date: this.end_date,
                integrations: this.reporting_integrations.map(integration => {
                  return {
                    integration_id: integration.id,
                  }
                }),
              },
            })
          .then((_response) => {
            this.is_loading = false;
            this.reset()
          })
          .catch((error) => {
            this.is_loading = false;
            this.errors  = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data);
          });
    },
    closed_requested() {
      this.reporting_integrations = this.build_reporting_integrations()
      this.$emit("closed_requested")
    },
  },
  computed: {
    start_min_time() {
      return moment(this.campaign.start_date).startOf('day').format('YYYY-MM-DD')
    },

    start_max_time() {
      return moment().startOf('day').format('YYYY-MM-DD')
    },
    end_min_time() {
      return moment(this.start_date).add(1, 'days').startOf('day').format('YYYY-MM-DD')
    },
    end_max_time() {
      return moment().add(1, 'days').startOf('day').format('YYYY-MM-DD')
    },
    disabled_button_class() {
      if (this.is_loading || this.is_blocked) {
        return "schedule-campaign-reporting--button--disabled"
      }
    },
  },
  watch: {
    "campaign"() {
      this.reset();
    },
    "edit"() {
      this.errors = null;
    },
  },
}
</script>

<style scoped>
</style>
