<template>
  <li :id="`media-planning-campaign-${campaign.id}`" class="media-planning-campaign" data-accordion-item>
    <div class="media-planning-campaign--accordion-title"
         :title="campaign.id"
         @click="toggle_content(`media-planning-campaign-content-${campaign.id}`)">

      <div class="media-planning-campaign--line">
        <channel-icon :channel_title="campaign.channel_title"></channel-icon>
        <span class="media-planning-campaign--channel-title">
          <span :title="campaign.id">{{ campaign.title }}</span>
          <i v-if="campaign.xv_order_id" :title="campaign.xv_order_id" class="fi-cloud"></i>
        </span>

        {{ format_time(campaign.start_date, "L") }}
        <template v-if="campaign.end_date">- {{ format_time(campaign.end_date, "L") }}</template>

        <boolean-icon v-if="campaign.recent" :color="true" title="Abgelaufen"></boolean-icon>
        <boolean-icon :bool="campaign.active_reporting" title="Reporting aktiv?" :color="false"></boolean-icon>

        <i v-if="can_manage && !is_blocked" @click.stop.prevent="open_campaign_dialog = true"
           title="Kampagne bearbeiten"
           class="fi-widget media-planning-campaign--channel-title-widget float-right"></i>
      </div>

      <div class="media-planning-campaign--line media-planning-campaign--grid2">
        <div class="media-planning-campaign--cell">
          <div class="media-planning-campaign--col">
            <div v-for="flight in campaign.flights">
              Flight:
              {{ format_time(flight.start_date, "L") }}
              <template v-if="flight.end_date">- {{ format_time(flight.end_date, "L") }}</template>

              <boolean-icon v-if="flight.recent" :color="true" title="Abgelaufen"></boolean-icon>
              <boolean-icon :bool="flight.active_reporting" title="Reporting aktiv?" :color="false"></boolean-icon>
            </div>
          </div>

          <div>
            <media-planning-campaign-integration v-for="integration in campaign.integrations"
                                                 :key="integration.id"
                                                 :media_planning="media_planning"
                                                 :campaign="campaign"
                                                 :integration="integration"
                                                 :integrations="integrations"
                                                 :google_ads_customers="google_ads_customers"
                                                 :active_agent_accounts="active_agent_accounts"
                                                 :outbrain_marketers="outbrain_marketers"
                                                 :enable_dialog="can_manage"
                                                 :enable_invidi_link="can_manage"
                                                 :is_blocked="is_blocked">
            </media-planning-campaign-integration>

            <button v-if="can_manage && addable_platforms.length && !is_blocked"
                    @click.stop.prevent="open_integration_dialog = true"
                    class="media-planning-campaign--button--label"
                    title="Kampagnen-Integration hinzufügen">
              <i class="fi-plus"></i>
            </button>
          </div>
        </div>
        <div class="media-planning-campaign--cell">
          <div class="media-planning-campaign--col float-right">
            <span v-if="campaign.target_type" title="Ziel">
              <i class="fi-target-two"></i>
              {{ campaign.target_value }}
              <span v-if="campaign.target_type === 'impressions'">Impressions</span>
              <span v-if="campaign.target_type === 'clicks'">Clicks</span>
              &middot;
            </span>

            <span v-if="campaign.turnover_type">
              <span title="Umsatzwert">
                <abbr v-if="campaign.turnover_type === 'tkp'" title="Tausender-Kontakt-Preis">TKP</abbr>
                <abbr v-if="campaign.turnover_type === 'cpc'" title="Cost per click">CPC</abbr>
                <abbr v-if="campaign.turnover_type === 'hf'" title="Handling fee: turnover = cost * (1.0 + hf)">HF</abbr>
                <abbr v-if="campaign.turnover_type === 'ghf'" title="Gross handling fee: turnover = cost / (1.0 - hf)">GHF</abbr>
                <vue-numeric v-if="campaign.turnover_value"
                             v-model="campaign.turnover_value"
                             separator="."
                             v-bind:precision=2
                             v-bind:read-only=true
                             :currency="['hf', 'ghf'].includes(campaign.turnover_type) ? '%' : 'Euro'"
                             currency-symbol-position="suffix">
                </vue-numeric>
                &middot;
                <template v-if="campaign.turnover_setup_fee">
                  <i class="fi-asterisk" :title="`Setup fee: ${campaign.turnover_setup_fee} €`"></i>
                  &middot;
                </template>
              </span>


              <span v-if="typeof campaign.turnover === 'number'" title="Umsatz">
                <vue-numeric v-model="campaign.turnover"
                             separator="."
                             v-bind:precision=2
                             v-bind:read-only=true
                             currency="Euro"
                             currency-symbol-position="suffix">
                </vue-numeric>
              </span>

              <template v-if="campaign.turnover_budget">
                &middot;
                <i class="fi-target" :title="`Budget: ${campaign.turnover_budget} €`"></i>
              </template>
            </span>

            <i v-if="campaign.dummy" class="fi-flag" title="Dummy-Kampagne ohne Integrationen"></i>

            <i v-if="campaign.turnover_type === 'natural_discount'" title="Naturalrabatt" class="fi-burst-sale"></i>
          </div>

          <div class="float-right">
            <campaign-complete-rate :campaign="campaign" :channels="channels"></campaign-complete-rate>

            <campaign-click-rate :campaign="campaign" :channels="channels"></campaign-click-rate>

            <campaign-conversion-rate :campaign="campaign" :channels="channels"></campaign-conversion-rate>

            <media-planning-campaign-target v-if="campaign.report_target_difference && !campaign.dummy"
                                            :campaign="campaign"></media-planning-campaign-target>

            <span v-if="can_manage && campaign.active_reporting && campaign.integrations.length" style="margin-right: 0.3em;">
              <schedule-campaign-reporting :media_planning="media_planning"
                                           :campaign="campaign"
                                           :show_text="false"
                                           :is_blocked="is_blocked"
                                           :can_manage="can_manage">
              </schedule-campaign-reporting>
            </span>

            <media-planning-campaign-dialog v-if="open_campaign_dialog"
                                            :media_planning="media_planning"
                                            :campaign="campaign"
                                            :integrations="integrations"
                                            :channels="channels"
                                            @closed_requested="open_campaign_dialog = false">
            </media-planning-campaign-dialog>

            <media-planning-campaign-integration-dialog v-if="open_integration_dialog"
                                                        :media_planning="media_planning"
                                                        :campaign="campaign"
                                                        :integrations="integrations"
                                                        :google_ads_customers="google_ads_customers"
                                                        :active_agent_accounts="active_agent_accounts"
                                                        :outbrain_marketers="outbrain_marketers"
                                                        :is_blocked="is_blocked"
                                                        @closed_requested="open_integration_dialog = false">
            </media-planning-campaign-integration-dialog>
          </div>
        </div>
      </div>
    </div>

    <div :id="`media-planning-campaign-content-${campaign.id}`"
         class="media-planning-campaign--accordion-content"
         data-tab-content>
      <media-planning-campaign-reports :media_planning="media_planning"
                                       :campaign="campaign"
                                       :channels="channels"
                                       :can_manage="can_manage">
      </media-planning-campaign-reports>

      <div class="media-planning-campaign--line media-planning-campaign--grid2">
        <div class="media-planning-campaign--cell">
          <ad-group-metrics v-if="campaign.include_ad_group_metrics" :campaign="campaign"></ad-group-metrics>
        </div>
        <div class="media-planning-campaign--cell" style="flex: auto; margin-left: 1em !important;">
          <keyword-metrics v-if="campaign.include_keyword_metrics" :campaign="campaign"></keyword-metrics>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import "./media-planning-campaign.scss";
import $ from 'jquery'
import moment from "moment";
import VueNumeric from 'vue-numeric';
import ChannelIcon from "../channel-icon/channel-icon-app"
import BaseModal from "../base-modal/base-modal-app"
import PercentRate from "../percent-rate/percent-rate.vue";
import MediaPlanningCampaignDialog from "../media-planning-campaign-dialog/media-planning-campaign-dialog-app"
import MediaPlanningCampaignTarget from "../media-planning-campaign-target/media-planning-campaign-target-app"
import MediaPlanningCampaignIntegration
  from "../media-planning-campaign-integration/media-planning-campaign-integration-app"
import MediaPlanningCampaignIntegrationDialog
  from "../media-planning-campaign-integration-dialog/media-planning-campaign-integration-dialog-app"
import MediaPlanningCampaignReports from "../media-planning-campaign-reports/media-planning-campaign-reports-app"
import BooleanIcon from '../boolean-icon/boolean-icon'
import CampaignCompleteRate from "../campaign-complete-rate/campaign-complete-rate-app"
import CampaignClickRate from "../campaign-click-rate/campaign-click-rate-app";
import CampaignConversionRate from "../campaign-conversion-rate/campaign-conversion-rate-app";
import ScheduleCampaignReporting from "../schedule-campaign-reporting/schedule-campaign-reporting-app"
import channel_rates from "../../lib/channel-rates";
import AdGroupMetrics from "../ad-group-metrics/ad-group-metrics-app.vue";
import KeywordMetrics from "../keyword-metrics/keyword-metrics-app.vue";

export default {
  name:       "media-planning-campaign",
  props:      {
    media_planning:        {
      required: true,
    },
    campaign:              {
      required: true,
    },
    integrations:          {
      required: true,
    },
    channels:              {
      required: true,
    },
    google_ads_customers:  {
      required: true,
    },
    active_agent_accounts: {
      required: true,
    },
    outbrain_marketers:    {
      required: true,
    },
    can_manage:            {
      required: true,
    },
    is_blocked:            {
      required: true,
    },
  },
  components: {
    AdGroupMetrics,
    KeywordMetrics,
    CampaignClickRate,
    CampaignCompleteRate,
    CampaignConversionRate,
    ChannelIcon,
    BaseModal,
    PercentRate,
    MediaPlanningCampaignDialog,
    MediaPlanningCampaignTarget,
    MediaPlanningCampaignIntegration,
    MediaPlanningCampaignIntegrationDialog,
    MediaPlanningCampaignReports,
    ScheduleCampaignReporting,
    BooleanIcon,
    VueNumeric,
  },
  data() {
    return {
      open_campaign_dialog:    false,
      open_integration_dialog: false,
    }
  },
  methods:  {
    toggle_content(target_id) {
      $("#accordion-container").foundation('toggle', $(`#${target_id}`));
    },
    min_rate(channel_title, rate) {
      return channel_rates(this.channels, channel_title, rate)
    },
    format_time(time, format = 'L') {
      if (!time) return;
      return moment(time).format(format)
    },
  },
  computed: {
    addable_platforms() {
      if (this.campaign.dummy) return []

      const integration = this.integrations.find(integration => {
        return this.campaign.channel_title === integration.channel_title
      })

      if (!integration) return []

      return integration.platforms.filter(platform => platform.enabled)
    },
  },
}
</script>
