<template>
  <form v-if="can_manage" @submit.prevent="register_media_planning" ref="form" class="register-media-planning">
    <h1>Neuer Mediaplan</h1>
    <label for="media_planning_title">Mediaplan-Titel</label>
    <div class="input-group">
      <input type="text" v-model="title" class="input-group-field" id="media_planning_title" :disabled="is_loading">
      <div class="input-group-button">
        <input type="submit" class="button" value="Mediaplan speichern" :disabled="!title || is_loading">
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: "register-media-planning",
  props: {
    can_manage: {
      required: true,
    },
  },
  data() {
    return {
      title: "",
      is_loading: false,
    }
  },
  methods: {
    register_media_planning() {
      if (!this.can_manage) return
      if (!this.title) return
      if (this.is_loading) return
      this.is_loading = true

      axios({
              method: 'post',
              url: `/media_plannings/register`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                title: this.title,
              },
            })
          .then((response) => {
            window.location.href = response.headers.location
          })
          .catch((error) => {
            this.is_loading = false
            console.log("ERROR", error.response.data);
          });
    }
  },
}
</script>

<style scoped>
</style>
