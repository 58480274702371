<template>
  <div class="report-click-adjustment-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_dialog">
        <form @submit.prevent="save_click_adjustment" ref="form">
          <h3>{{ media_planning.title }}</h3>
          <h4>
            <channel-icon :channel_title="campaign.channel_title"></channel-icon>
            {{ campaign.title }}
            /
            {{ report.platform_title }}
            /
            {{ format_date_time(report.date) }}
          </h4>

          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            Korrektur der Clicks
              ({{ report.clicks }})
            um:
            <vue-numeric v-model="click_adjustment"
                         v-bind:precision=0
                         :minus="true"
                         type="number"
                         separator=""
                         :min="min_adjustment"
                         class="report-click-adjustment-dialog--input">
            </vue-numeric>
            auf
            {{ report.clicks + click_adjustment }}
          </fieldset>

          <div class="report-click-adjustment-dialog--actions">
            <button class="report-click-adjustment-dialog--button"
                    :class="disabled_button_class"
                    :disabled="loading">
              <i class="fi-save"></i>
              Korrektur speichern
            </button>

            <button @click.stop="close_dialog"
                    :disabled="loading"
                    :class="disabled_button_class"
                    class="report-click-adjustment-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./report-click-adjustment-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import ChannelIcon from "../channel-icon/channel-icon-app"
import VueNumeric from 'vue-numeric'
import moment from "moment"
import axios from "axios"

export default {
  name: "report-click-adjustment-dialog",
  props: {
    media_planning: {
      required: true,
    },
    campaign: {
      required: true,
    },
    report: {
      required: true,
    },
  },
  components: {
    BaseModal,
    ChannelIcon,
    VueNumeric,
  },
  data() {
    return {
      click_adjustment: this.report.click_adjustment,
      loading: false,
      errors: null,
    }
  },
  methods: {
    save_click_adjustment() {
      if (this.loading) return
      this.loading = true
      this.errors  = null

      axios({
              method: 'post',
              url: `/media_plannings/${this.media_planning.id}/adjust_report_clicks`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                campaign_id: this.campaign.id,
                campaign_integration_id: this.report.campaign_integration_id,
                date: this.report.date,
                click_adjustment: this.click_adjustment,
              },
            })
          .then((_response) => {
            this.loading = false;
            this.close_dialog()
          })
          .catch((error) => {
            this.loading = false;
            this.errors  = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data);
          });
    },
    format_date_time(date_time, format = "L") {
      return moment(date_time).format(format)
    },
    close_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    min_adjustment() {
      return -this.report.clicks
    },
    disabled_button_class() {
      if (this.loading) {
        return "report-click-adjustment-dialog--button--disabled"
      }
    },
  },
}
</script>
