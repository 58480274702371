<template>
  <div class="google-ads-customer-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_google_ads_customer_dialog">
        <h3 v-if="google_ads_customer.persisted">GoogleAds Customer aktualisieren</h3>
        <h3 v-else>Neuer GoogleAds Customer</h3>

        <form @submit.prevent="save_google_ads_customer" ref="form">
          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label>
              ID:
              <input type="text"
                     v-model="google_ads_customer.id"
                     :disabled="google_ads_customer.persisted"
                     placeholder="517-517-2470">
            </label>
            <label>
              Titel:
              <input type="text"
                     v-model="google_ads_customer.title"
                     placeholder="Live-Kampagnen">
            </label>
          </fieldset>

          <fieldset>
            <label>Account-Typ:</label>
            <template v-for="account in google_ads_customer_accounts">
              <input type="radio"
                     name="account_id"
                     :value="account.id"
                     :id="`account_${account.id}`"
                     v-model="google_ads_customer.account_id"
                     :disabled="loading">
              <label :for="`account_${account.id}`">
                {{ account.name }}
              </label>
            </template>
          </fieldset>

          <div>
            <a v-if="google_ads_customer.persisted && !google_ads_customer.deleted"
               @click.stop.prevent="delete_google_ads_customer"
               :class="disabled_button_class"
               class="google-ads-customer-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </a>
            <a v-if="google_ads_customer.persisted && google_ads_customer.deleted"
               @click.stop.prevent="restore_google_ads_customer"
               :class="disabled_button_class"
               class="google-ads-customer-dialog--button--alert">
              <i class="fi-alert"></i>
              Wiederherstellen ...
            </a>
            <a @click="close_google_ads_customer_dialog" class="google-ads-customer-dialog--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </a>
            <button v-if="google_ads_customer_configured" :class="disabled_button_class">
              <i class="fi-save"></i>
              <span v-if="google_ads_customer.persisted">GoogleAds Customer aktualisieren</span>
              <span v-else>GoogleAds Customer hinzufügen</span>
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./google-ads-customer-dialog.scss";
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "google-ads-customer-dialog",
  props: {
    google_ads_customer: {
      required: false,
      default() {
        return {
          id: null,
          title: null,
          persisted: false,
        }
      },
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      google_ads_customer_accounts: [],
      loading: false,
      errors: null,
    }
  },
  methods: {
    save_google_ads_customer() {
      if (!this.google_ads_customer_configured) return
      if (this.loading) return

      this.loading = true
      this.errors  = null

      if (this.google_ads_customer.persisted) {
        axios({
                method: 'post',
                url: `/google_ads_customers/${this.google_ads_customer.id}/update_title`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                data: {
                  id: this.google_ads_customer.id,
                  title: this.google_ads_customer.title,
                  account_id: this.google_ads_customer.account_id,
                },
              })
            .then((_response) => {
              this.loading = false;
              this.close_google_ads_customer_dialog()
            })
            .catch((error) => {
              this.loading = false;
              this.errors  = error.response.data.errors.map(error => error.title)
              console.log("ERROR", error.response.data);
            });
      } else {
        axios({
                method: 'post',
                url: `/google_ads_customers/register`,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                data: {
                  id: this.google_ads_customer.id,
                  title: this.google_ads_customer.title,
                  account_id: this.google_ads_customer.account_id,
                },
              })
            .then((_response) => {
              this.loading = false;
              this.close_google_ads_customer_dialog()
            })
            .catch((error) => {
              this.loading = false;
              this.errors  = error.response.data.errors.map(error => error.title)
              console.log("ERROR", error.response.data);
            })
      }
    },
    delete_google_ads_customer() {
      let confirmation = confirm("Willst du den GooogleAds Customer wirklich löschen?");
      if (!confirmation) return
      axios({
              method: 'delete',
              url: `/google_ads_customers/${this.google_ads_customer.id}/delete`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then((_response) => {
            this.loading = false;
            this.close_google_ads_customer_dialog()
          })
          .catch((error) => {
            this.loading = false;
            this.errors  = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data);
          });
    },
    restore_google_ads_customer() {
      let confirmation = confirm("Willst du den GooogleAds Customer wirklich wiederherstellen?");
      if (!confirmation) return
      axios({
              method: 'patch',
              url: `/google_ads_customers/${this.google_ads_customer.id}/restore`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then((_response) => {
            this.loading = false;
            this.close_google_ads_customer_dialog()
          })
          .catch((error) => {
            this.loading = false;
            this.errors  = error.response.data.errors.map(error => error.title)
            console.log("ERROR", error.response.data);
          });
    },
    close_google_ads_customer_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    google_ads_customer_configured() {
      if (!this.google_ads_customer.id) return false
      if (!this.google_ads_customer.title) return false
      if (!this.google_ads_customer.id.match(/^([0-9]|-)+$/)) return false
      return true
    },
    disabled_button_class() {
      if (this.google_ads_customer_configured) {
        return "google-ads-customer-dialog--button"
      } else {
        return "google-ads-customer-dialog--disabled"
      }
    },
  },
  mounted() {
    axios({
            method: 'get',
            url: `/google_ads_customer_accounts.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.google_ads_customer_accounts = response.data
        })
        .catch(error => {
          console.log("ERROR", error.response.data);
        });
  },
}
</script>
