<template>
  <div class="media-plannings">
    <h1>
      {{ displayable_media_plannings.length }} Mediapläne
      <small>{{ displayable_campaigns_count }} Kampagnen</small>
    </h1>

    <div class="media-plannings--commands">
      <button v-if="can_manage" @click="visit_new_media_planning_path"
              class="media-plannings--button"
              :class="disabled_button_class"
              :disabled="is_loading">
        <i class="fi-plus"></i>
        Neuer Mediaplan
      </button>

      <button v-if="can_manage"
              @click="schedule_media_plannings_reportings"
              class="media-plannings--button"
              :class="disabled_button_class"
              :disabled="is_loading">
        <i class="fi-refresh"></i>
        Starte alle Reportings
      </button>

      <button @click="toggle_live"
              class="media-plannings--button"
              :class="disabled_button_class"
              :disabled="is_loading">
        <boolean-icon :bool="filter_live" title="Nur Live-Kampagnen?" :color="false"></boolean-icon>
        Nur Live-Kampagnen?
      </button>

      <button @click="toggle_reporting"
              class="media-plannings--button"
              :class="disabled_button_class"
              :disabled="is_loading">
        <boolean-icon :bool="filter_reporting" title="Nur Reporting aktiv?" :color="false"></boolean-icon>
        Nur Reporting aktiv?
      </button>

      <button @click="toggle_soon_to_end"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur mit Kampagnen, die innerhalb der nächsten Woche auslaufen"
              :disabled="is_loading">
        <boolean-icon :bool="filter_soo_to_end" :color="false"></boolean-icon>
        Soon to end
      </button>

      <button @click="toggle_xv_order_id"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur Kampagnen ohne crossvertise Order-ID"
              :disabled="is_loading">
        <boolean-icon :bool="filter_missing_xv_order_id" :color="false"></boolean-icon>
        Ohne Order-ID
      </button>

      <button @click="toggle_weekly_reporting"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur Mediapläne mit wöchentlichem Reporting"
              :disabled="is_loading">
        <boolean-icon :bool="filter_weekly_reporting" :color="false"></boolean-icon>
        Weekly Reporting?
      </button>

      <button @click="toggle_detailed_final_reporting"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur Mediapläne mit detailliertem Endreporting"
              :disabled="is_loading">
        <boolean-icon :bool="filter_detailed_final_reporting" :color="false"></boolean-icon>
        Detailliertes Endreporting?
      </button>

      <button @click="toggle_notes"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur Mediapläne mit Notizen"
              :disabled="is_loading">
        <boolean-icon :bool="filter_notes" :color="false"></boolean-icon>
        Notizen?
      </button>

      <button @click="toggle_tasks"
              class="media-plannings--button"
              :class="disabled_button_class"
              title="Nur Mediapläne mit aktiven Tasks"
              :disabled="is_loading">
        <boolean-icon :bool="filter_tasks" :color="false"></boolean-icon>
        Tasks?
      </button>

      <button @click="approve_reports"
              class="media-plannings--button media-plannings--button--warning"
              :class="disabled_approve_button_class"
              :title="approval_title"
              :disabled="is_loading || reports_approved">
        <boolean-icon v-if="!approved_at" :bool="false" color="false"></boolean-icon>
        Reports freischalten
      </button>
    </div>

    <div class="media-plannings--selects">
      <select @change="select_manager($event)" class="media-plannings--manager-select" :disabled="is_loading">
        <option value="">- Alle Manager -</option>
        <option value="nil" :selected="manager_id === 'nil'">- Ohne Manager -</option>
        <option v-for="manager in managers" :value=manager.id :selected="manager.id === manager_id">
          {{ manager.email }}
        </option>
      </select>

      <select @change="select_team($event)" class="media-plannings--team-select" :disabled="is_loading">
        <option value="">- Alle Teams -</option>
        <option value="nil" :selected="team_id === 'nil'">- Ohne Team -</option>
        <option v-for="team in teams" :value=team.id :selected="team.id === team_id">
          {{ team.title }}
        </option>
      </select>

      <select @change="select_channel($event)" class="media-plannings--channel-select" :disabled="is_loading">
        <option value="">- Alle Channels -</option>
        <option v-for="channel in channels" :value=channel.id :selected="channel.id === channel_id">
          {{ channel.title }}
        </option>
      </select>

      <select @change="select_customer($event)" class="media-plannings--customer-select" :disabled="is_loading">
        <option value="">- Alle Kunden -</option>
        <option value="nil" :selected="customer_id === 'nil'">- Ohne Kunde -</option>
        <option v-for="customer in customers" :value=customer.id :selected="customer.id === customer_id">
          {{ customer.title }}
        </option>
      </select>

      <select @change="select_advertiser($event)" class="media-plannings--advertiser-select" :disabled="is_loading">
        <option value="">- Alle Advertiser -</option>
        <option value="nil" :selected="advertiser_id === 'nil'">- Ohne Advertiser -</option>
        <option v-for="advertiser in advertisers" :value=advertiser.id :selected="advertiser.id === advertiser_id">
          {{ advertiser.title }}
        </option>
      </select>

      <select @change="select_turnover_group($event)" class="media-plannings--turnover-group-select" :disabled="is_loading">
        <option value="">- Alle Umsatzgruppen -</option>
        <option v-for="turnover_group in turnover_groups" :value=turnover_group.id :selected="turnover_group.id === turnover_group_id">
          {{ turnover_group.title }}
        </option>
      </select>
    </div>

    <div v-if="sorted_campaigns(media_planning).length"
         v-for="media_planning in displayable_media_plannings"
         @click="visit_media_planning(media_planning)"
         class="media-plannings--media-planning">
      <h4>
        {{ media_planning.title }}

        <span v-if="media_planning.reporting_status" class="float-right">
          <report-status :status="media_planning.reporting_status"></report-status>
        </span>
        <span v-if="turnover_group(media_planning)" class="media-plannings--turnover-group float-right" :title="turnover_group(media_planning).description">
          {{ turnover_group(media_planning).title }}
        </span>
        <span v-if="media_planning.tasks_count" :title="`${media_planning.tasks_count} aktive Aufgabe${media_planning.tasks_count > 1 ? 'n' : ''}`" class="media-plannings--tasks-count float-right">
          <i class="fi-list"></i>
        </span>
        <span v-if="media_planning.notes" title="Mit Notizen" class="media-plannings--notes float-right">
          <i class="fi-comment-quotes"></i>
        </span>
        <span v-if="media_planning.detailed_final_reporting" title="Detailliertes Endreporting" class="media-plannings--detailed-final-reporting float-right">
          <i class="fi-graph-bar"></i>
        </span>
        <span v-if="media_planning.weekly_reporting" title="Weekly Reporting" class="media-plannings--weekly-reporting float-right">
          <i class="fi-graph-horizontal"></i>
        </span>
        <span v-if="media_planning.xv_order_id || media_planning.campaigns.every(campaign => campaign.xv_order_id)"
              :title="media_planning.xv_order_id"
              class="media-plannings--xy-order-id float-right">
          <i class="fi-cloud"></i>
        </span>
        <span v-if="advertiser_title(media_planning)" class="media-plannings--advertiser-title float-right">
          {{ advertiser_title(media_planning) }}
        </span>
        <span v-if="customer_title(media_planning)" class="media-plannings--customer-title float-right">
          {{ customer_title(media_planning) }}
        </span>
        <span v-if="manager_email(media_planning)" class="media-plannings--manager-email float-right">
          {{ manager_email(media_planning) }}
        </span>
        <span v-if="team_title(media_planning)" class="media-plannings--team-title float-right">
          {{ team_title(media_planning) }}
        </span>
      </h4>

      <div v-for="campaign in sorted_campaigns(media_planning)" class="media-plannings--grid4">
        <div class="media-plannings--cell">
          <channel-icon :channel_title="campaign.channel_title"></channel-icon>
          {{ campaign.title }}
          <i v-if="campaign.dummy" class="fi-flag" title="Dummy-Kampagne ohne Integrationen"></i>
          <i v-if="campaign.turnover_type === 'natural_discount'" title="Naturalrabatt" class="fi-burst-sale"></i>
          <i v-if="campaign.turnover_budget" :title="`Budget: ${campaign.turnover_budget} €`" class="fi-target"></i>
          <i v-if="campaign.turnover_setup_fee" :title="`Setup fee: ${campaign.turnover_setup_fee} €`" class="fi-asterisk"></i>
          <i v-if="campaign.xv_order_id" :title="campaign.xv_order_id" class="fi-cloud"></i>
        </div>
        <div class="media-plannings--cell">
          {{ format_time(campaign.start_date) }}
          <template v-if="campaign.end_date">
            -{{ format_time(campaign.end_date) }}
            <boolean-icon v-if="campaign.recent" :color="true"></boolean-icon>
          </template>
        </div>
        <div class="media-plannings--cell">
          <template v-if="!campaign.dummy">
            <campaign-click-rate :campaign="campaign" :channels="channels"></campaign-click-rate>
            <campaign-complete-rate :campaign="campaign" :channels="channels"></campaign-complete-rate>
            <campaign-video-view-rate :campaign="campaign" :channels="channels"></campaign-video-view-rate>
            <campaign-conversion-rate :campaign="campaign" :channels="channels"></campaign-conversion-rate>
            <media-planning-campaign-target v-if="campaign.report_target_difference" :campaign="campaign">
            </media-planning-campaign-target>
          </template>
        </div>
        <div class="media-plannings--cell--auto">
          <media-planning-campaign-integration v-for="integration in campaign.integrations"
                                               :key="integration.id"
                                               :media_planning="media_planning"
                                               :campaign="campaign"
                                               :integration="integration"
                                               :integrations="integrations"
                                               :google_ads_customers="google_ads_customers"
                                               :active_agent_accounts="[]"
                                               :outbrain_marketers="[]"
                                               :enable_dialog="false"
                                               :is_blocked="false">
          </media-planning-campaign-integration>
        </div>
      </div>
    </div>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import ReportStatus from "../report-status/report-status-app"
import ReportingStatus from "../report-status/report-status-app"
import BooleanIcon from '../boolean-icon/boolean-icon'
import PercentRate from "../percent-rate/percent-rate.vue"
import ScheduleCampaignReporting from "../schedule-campaign-reporting/schedule-campaign-reporting-app"
import ChannelIcon from "../channel-icon/channel-icon-app"
import CampaignClickRate from "../campaign-click-rate/campaign-click-rate-app"
import CampaignCompleteRate from "../campaign-complete-rate/campaign-complete-rate-app"
import CampaignVideoViewRate from "../campaign-video-view-rate/campaign-video-view-rate-app.vue";
import CampaignConversionRate from "../campaign-conversion-rate/campaign-conversion-rate-app.vue";
import MediaPlanningCampaignIntegration
  from "../media-planning-campaign-integration/media-planning-campaign-integration-app"
import MediaPlanningCampaignTarget from "../media-planning-campaign-target/media-planning-campaign-target-app"

import channel_rates from "../../lib/channel-rates"

export default {
  name: "media-plannings-app",
  props: {
    original_media_plannings: {
      required: true,
    },
    channels: {
      required: true,
    },
    integrations: {
      required: true,
    },
    turnover_groups: {
      required: true,
    },
    google_ads_customers: {
      required: true,
    },
    users: {
      required: true,
    },
    teams: {
      required: true,
    },
    customers: {
      required: true,
    },
    advertisers: {
      required: true,
    },
    original_manager_id: {
      required: true,
    },
    original_team_id: {
      required: true,
    },
    original_customer_id: {
      required: true,
    },
    original_channel_id: {
      required: true,
    },
    original_advertiser_id: {
      required: true,
    },
    original_turnover_group_id: {
      required: true,
    },
    original_filter_soo_to_end: {
      required: true,
    },
    original_filter_missing_xv_order_id: {
      required: true,
    },
    original_filter_weekly_reporting: {
      required: true,
    },
    original_filter_detailed_final_reporting: {
      required: true,
    },
    original_filter_notes: {
      required: true,
    },
    original_filter_tasks: {
      required: true,
    },
    original_filter_live: {
      required: true,
    },
    original_filter_reporting: {
      required: true,
    },
    can_manage: {
      required: true,
    },
  },
  components: {
    BooleanIcon,
    ReportStatus,
    PercentRate,
    ScheduleCampaignReporting,
    ChannelIcon,
    ReportingStatus,
    CampaignClickRate,
    CampaignCompleteRate,
    CampaignVideoViewRate,
    CampaignConversionRate,
    MediaPlanningCampaignIntegration,
    MediaPlanningCampaignTarget,
  },
  data() {
    return {
      media_plannings: this.original_media_plannings,
      filter_live: this.original_filter_live ? 1 : 0,
      filter_reporting: this.original_filter_reporting ? 1 : 0,
      filter_soo_to_end: this.original_filter_soo_to_end ? 1 : 0,
      filter_missing_xv_order_id: this.original_filter_missing_xv_order_id ? 1 : 0,
      filter_weekly_reporting: this.original_filter_weekly_reporting ? 1 : 0,
      filter_detailed_final_reporting: this.original_filter_detailed_final_reporting ? 1 : 0,
      filter_notes: this.original_filter_notes ? 1 : 0,
      filter_tasks: this.original_filter_tasks ? 1 : 0,
      manager_id: this.original_manager_id,
      team_id: this.original_team_id,
      customer_id: this.original_customer_id,
      channel_id: this.original_channel_id,
      advertiser_id: this.original_advertiser_id,
      turnover_group_id: this.original_turnover_group_id,
      is_loading: false,
      approved_at: null,
    }
  },
  methods: {
    schedule_media_plannings_reportings() {
      if (!this.can_manage) return
      if (this.is_loading) return
      this.is_loading = true

      this.displayable_media_plannings.forEach((media_planning, index) => {
        if (!media_planning.active_reporting) return

        media_planning.campaigns.forEach(campaign => {
          if (!campaign.active_reporting) return
          if (!campaign.integrations.length) return

          let reporting_interval = Math.max(...campaign.integrations.map(integration => integration.default_reporting_interval))

          setTimeout(() => {
            axios({
                    method: 'post',
                    url: `/media_plannings/${media_planning.id}/schedule_reporting`,
                    headers: {'X-Requested-With': 'XMLHttpRequest'},
                    data: {
                      campaign_id: campaign.id,
                      start_date: moment().subtract(reporting_interval, 'seconds').startOf('day').format('YYYY-MM-DD'),
                      end_date: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
                      integrations: campaign.integrations.map(integration => {
                        return {
                          integration_id: integration.id,
                        }
                      }),
                    },
                  })
          }, index * 10000)
        })
      })
      this.is_loading = false
    },
    format_time(time, format = 'L') {
      if (!time) return
      return moment(time).format(format)
    },
    visit_new_media_planning_path() {
      this.is_loading      = true
      window.location.href = '/media_plannings/new'
    },
    visit_media_planning(media_planning, new_tab = true) {
      if (new_tab) {
        window.open(media_planning.path, '_blank').focus()
      } else {
        window.location.href = media_planning.path
      }
    },
    min_rate(channel_title, rate) {
      return channel_rates(this.channels, channel_title, rate)
    },
    toggle_reporting() {
      this.is_loading = true
      if (this.filter_reporting) {
        this.filter_reporting = 0
      } else {
        this.filter_reporting = 1
      }
      setTimeout(() => {
        history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
        window.location.href = `/media_plannings?${new URLSearchParams(this.search_params)}`
      }, 0)
    },
    toggle_live() {
      this.is_loading = true
      if (this.filter_live) {
        this.filter_live = 0
      } else {
        this.filter_live = 1
      }
      setTimeout(() => {
        history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
        window.location.href = `/media_plannings?${new URLSearchParams(this.search_params)}`
      }, 0)
    },
    toggle_soon_to_end() {
      this.is_loading = true
      if (this.filter_soo_to_end) {
        this.filter_soo_to_end = 0
      } else {
        this.filter_soo_to_end = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    toggle_xv_order_id() {
      this.is_loading = true
      if (this.filter_missing_xv_order_id) {
        this.filter_missing_xv_order_id = 0
      } else {
        this.filter_missing_xv_order_id = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    toggle_weekly_reporting() {
      this.is_loading = true
      if (this.filter_weekly_reporting) {
        this.filter_weekly_reporting = 0
      } else {
        this.filter_weekly_reporting = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    toggle_detailed_final_reporting() {
      this.is_loading = true
      if (this.filter_detailed_final_reporting) {
        this.filter_detailed_final_reporting = 0
      } else {
        this.filter_detailed_final_reporting = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    toggle_notes() {
      this.is_loading = true
      if (this.filter_notes) {
        this.filter_notes = 0
      } else {
        this.filter_notes = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    toggle_tasks() {
      this.is_loading = true
      if (this.filter_tasks) {
        this.filter_tasks = 0
      } else {
        this.filter_tasks = 1
      }
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_manager(event) {
      this.is_loading = true
      this.manager_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_team(event) {
      this.is_loading = true
      this.team_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_channel(event) {
      this.is_loading = true
      this.channel_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_customer(event) {
      this.is_loading  = true
      this.customer_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_advertiser(event) {
      this.is_loading    = true
      this.advertiser_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    select_turnover_group(event) {
      this.is_loading        = true
      this.turnover_group_id = event.target.value
      history.pushState({}, '', `/media_plannings?${new URLSearchParams(this.search_params)}`)
      this.is_loading = false
    },
    manager_email(media_planning) {
      if (media_planning.manager_id === null) return
      const manager = this.users.find(user => user.id === media_planning.manager_id)
      if (manager) return manager.email
    },
    team_title(media_planning) {
      if (media_planning.manager_id === null) return
      const manager = this.users.find(user => user.id === media_planning.manager_id)
      if (!manager) return
      const team = this.teams.find(team => team.id === manager.team_id)
      if (team) return team.title
    },
    customer_title(media_planning) {
      if (media_planning.customer_id === null) return
      const customer = this.customers.find(customer => customer.id === media_planning.customer_id)
      if (customer) return customer.title
    },
    advertiser_title(media_planning) {
      if (media_planning.advertiser_id === null) return
      const advertiser = this.advertisers.find(advertiser => advertiser.id === media_planning.advertiser_id)
      if (advertiser) return advertiser.title
    },
    turnover_group(media_planning) {
      return this.turnover_groups.find(turnover_group => turnover_group.id === media_planning.turnover_group_id)
    },
    sorted_campaigns(media_planning) {
      return media_planning
          .campaigns
          .slice()
          .filter(campaign => {
            if (this.channel_id) {
              return this.channel_id === campaign.channel_id
            }
            return true
          })
          .sort((c1, c2) => {
            if (c1.end_date === null && c2.end_date === null) {
              return ((c1.start_date) > (c2.start_date)) ? 1 : -1
            }

            if (c1.end_date !== null && c2.end_date === null) {
              return ((c1.end_date) > (c2.start_date)) ? -1 : 1
            }

            if (c1.end_date === null && c2.end_date !== null) {
              return ((c1.start_date) > (c2.end_date)) ? -1 : 1
            }

            return c1.end_date > c2.end_date ? 1 : -1
          })
    },
    approve_reports() {
      if (this.is_loading) return

      let confirmation = confirm("Willst du die Reports wirklich freischalten?")
      if (!confirmation) return

      this.is_loading = true

      axios({
              method: 'post',
              url: `/media_plannings/approve`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
            })
          .then(response => {
            this.is_loading = false
            if (response.data.approved_at) {
              this.approved_at = Date.parse(response.data.approved_at)
            }
          })
          .catch(error => {
            this.is_loading = false
            console.log("ERROR", error)
          })
    },
  },
  computed: {
    displayable_media_plannings() {
      return this.media_plannings.filter(media_planning => {
        if (this.filter_live && !media_planning.live) return false // Not necessary probably
        if (this.filter_reporting && !media_planning.active_reporting) return false // Not necessary probably
        if (this.manager_id) {
          if (this.manager_id === "nil") {
            if (media_planning.manager_id) return false
            if (media_planning.partner_manager_id) return false
            if (media_planning.substitute_manager_id) return false
          } else if (media_planning.manager_id !== this.manager_id && media_planning.partner_manager_id !== this.manager_id && media_planning.substitute_manager_id !== this.manager_id) {
            return false
          }
        }
        if (this.team_id) {
          const managers = this.users.filter(user => {
            return [media_planning.manager_id, media_planning.partner_manager_id, media_planning.substitute_manager_id].includes(user.id);
          })
          if (managers.length === 0) return true

          if (this.team_id === "nil") {
            return managers.every(manager => !manager.team_id)
          } else {
            return managers.some(manager => manager.team_id === this.team_id)
          }
        }
        if (this.customer_id) {
          if (this.customer_id === "nil") {
            if (media_planning.customer_id) return false
          } else if (media_planning.customer_id !== this.customer_id) {
            return false
          }
        }
        if (this.advertiser_id) {
          if (this.advertiser_id === "nil") {
            if (media_planning.advertiser_id) return false
          } else if (media_planning.advertiser_id !== this.advertiser_id) {
            return false
          }
        }
        if (this.turnover_group_id) {
          if (media_planning.turnover_group_id !== this.turnover_group_id) {
            return false
          }
        }
        if (this.channel_id) {
          const has_channel_campaign = media_planning.campaigns.some(campaign => {
            return campaign.channel_id === this.channel_id
          })
          if (!has_channel_campaign) return false
        }
        if (this.filter_soo_to_end) {
          const has_soon_ending_campaigns = media_planning.campaigns.some(campaign => {
            if (moment(campaign.end_date) < moment()) return false
            if (moment(campaign.end_date) > moment().add(7, 'days')) return false
            return true
          })
          if (!has_soon_ending_campaigns) return false
        }
        if (this.filter_missing_xv_order_id) {
          const campaigns_all_with_xv_order_id = media_planning.campaigns.every(campaign => campaign.xv_order_id)
          if (campaigns_all_with_xv_order_id) return false
        }
        if (this.filter_weekly_reporting) {
          if (!media_planning.weekly_reporting) return false
        }
        if (this.filter_detailed_final_reporting) {
          if (!media_planning.detailed_final_reporting) return false
        }
        if (this.filter_notes) {
          if (!media_planning.notes) return false
        }
        if (this.filter_tasks) {
          if (media_planning.tasks_count === 0) return false
        }
        return true
      }).sort((a, b) => {
        if (a.end_date === null && b.end_date === null) {
          return ((a.start_date) > (b.start_date)) ? 1 : -1
        }

        if (a.end_date !== null && b.end_date === null) {
          return ((a.end_date) > (b.start_date)) ? -1 : 1
        }

        if (a.end_date === null && b.end_date !== null) {
          return ((a.start_date) > (b.end_date)) ? -1 : 1
        }

        return a.end_date > b.end_date ? 1 : -1
      })
    },
    displayable_campaigns_count() {
      let count = 0
      this.displayable_media_plannings.forEach(media_planning => {
        media_planning.campaigns.forEach(campaign => {
          if (this.channel_id) {
            if (this.channel_id !== campaign.channel_id) return
          }
          count = count + 1
        })
      })
      return count
    },
    managers() {
      return this.users.filter(user => user.role === "manager")
    },
    search_params() {
      const search_params = {}

      search_params["filter_live"]      = this.filter_live ? 1 : 0
      search_params["filter_reporting"] = this.filter_reporting ? 1 : 0
      if (this.filter_soo_to_end) search_params["filter_soo_to_end"] = this.filter_soo_to_end ? 1 : 0
      if (this.filter_missing_xv_order_id) search_params["filter_missing_xv_order_id"] = this.filter_missing_xv_order_id ? 1 : 0
      if (this.filter_weekly_reporting) search_params["filter_weekly_reporting"] = this.filter_weekly_reporting ? 1 : 0
      if (this.filter_detailed_final_reporting) search_params["filter_detailed_final_reporting"] = this.filter_detailed_final_reporting ? 1 : 0
      if (this.filter_notes) search_params["filter_notes"] = this.filter_notes ? 1 : 0
      if (this.filter_tasks) search_params["filter_tasks"] = this.filter_tasks ? 1 : 0

      if (this.manager_id) search_params["manager_id"] = this.manager_id
      if (this.team_id) search_params["team_id"] = this.team_id
      if (this.channel_id) search_params["channel_id"] = this.channel_id
      if (this.customer_id) search_params["customer_id"] = this.customer_id
      if (this.advertiser_id) search_params["advertiser_id"] = this.advertiser_id
      if (this.turnover_group_id) search_params["turnover_group_id"] = this.turnover_group_id

      return search_params
    },
    disabled_button_class() {
      if (this.is_loading) {
        return "media-plannings--button--disabled"
      }
    },
    disabled_approve_button_class() {
      if (this.is_loading || this.reports_approved) {
        return "media-plannings--button--disabled"
      }
    },
    approval_title() {
      if (this.approved_at) {
        const approved_at = moment(this.approved_at).format("LLL");
        return `Reports sind über die xv API bis ${approved_at} Uhr freigeschaltet`
      } else {
        return "Reports sind über die xv API noch nicht freigeschaltet"
      }
    },
    reports_approved() {
      if (!this.approved_at) return false
      return moment(this.approved_at).isSameOrAfter(moment().startOf('day'))
    },
  },
  channels: {
    MediaPlanningRegisteredChannel: {
      connected() {
      },
      rejected() {
      },
      received(media_planning_json) {
        console.log("MediaPlanningRegisteredChannel received")
        const media_planning = JSON.parse(media_planning_json)
        this.media_plannings.push(media_planning)
        this.$cable.subscribe({
                                channel: 'MediaPlanningUpdatedChannel',
                                media_planning_id: media_planning.id
                              })
      },
      disconnected() {
      },
    },
    MediaPlanningUpdatedChannel: {
      connected() {
      },
      rejected() {
      },
      received(media_planning_json) {
        console.log("MediaPlanningUpdatedChannel received")
        const received_media_planning = JSON.parse(media_planning_json)
        const media_planning          = this.media_plannings.find(c => {
          return c.id === received_media_planning.id
        })
        if (!media_planning) return
        Object.assign(media_planning, received_media_planning)
        console.log("MediaPlanning updated", media_planning)
      },
      disconnected() {
      },
    },
    MediaPlanningDeletedChannel: {
      connected() {
      },
      rejected() {
      },
      received(media_planning_json) {
        console.log("MediaPlanningDeletedChannel received")
        const received_media_planning = JSON.parse(media_planning_json)
        const index                   = this.media_plannings.findIndex(c => {
          return c.id === received_media_planning.id
        })
        if (index === -1) return
        this.media_plannings.splice(index, 1)
      },
      disconnected() {
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
                            channel: 'MediaPlanningRegisteredChannel',
                            stream: "media_planning_registered",
                          })

    this.media_plannings.forEach(media_planning => {
      this.$cable.subscribe({
                              channel: 'MediaPlanningUpdatedChannel',
                              media_planning_id: media_planning.id
                            })
      this.$cable.subscribe({
                              channel: 'MediaPlanningDeletedChannel',
                              media_planning_id: media_planning.id
                            })
    })
    axios({
            method: 'get',
            url: `/media_plannings/approved_at`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          if (response.data.approved_at) {
            this.approved_at = Date.parse(response.data.approved_at)
          }
        })
        .catch(error => {
          console.log("ERROR", error)
        })
  },
}
</script>

<style scoped>
</style>
