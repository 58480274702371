<template>
  <span @click.stop.prevent="toggle_dialog"
        :class="link_class"
        :title="integration.code"
        class="media-planning-campaign-integration">
    {{ integration.platform_title }}

    <button v-if="invidi_link" @click.stop.prevent="visit_invidi_campaign" :title="`Invidi-Kampagne: ${integration.code}`">
      <i class="fi-link"></i>
    </button>

    <i v-if="integration.use_viewable_impressions" class="fi-flag"></i>

    <media-planning-campaign-integration-dialog v-if="open_integration_dialog"
                                                :media_planning="media_planning"
                                                :campaign="campaign"
                                                :integration="integration"
                                                :integrations="integrations"
                                                :google_ads_customers="google_ads_customers"
                                                :active_agent_accounts="active_agent_accounts"
                                                :outbrain_marketers="outbrain_marketers"
                                                :is_blocked="is_blocked"
                                                @closed_requested="open_integration_dialog = false">
    </media-planning-campaign-integration-dialog>
  </span>
</template>

<script>
import "./media-planning-campaign-integration.scss";
import MediaPlanningCampaignIntegrationDialog
  from "../media-planning-campaign-integration-dialog/media-planning-campaign-integration-dialog-app"

export default {
  name: "media-planning-campaign-integration",
  props: {
    media_planning: {
      required: true,
    },
    campaign: {
      required: true,
    },
    integration: {
      required: true,
    },
    integrations: {
      required: true,
    },
    google_ads_customers: {
      required: true,
    },
    active_agent_accounts: {
      required: true,
    },
    outbrain_marketers: {
      required: true,
    },
    enable_dialog: {
      required: false,
      default() {
        return true
      },
    },
    enable_invidi_link: {
      required: false,
      default() {
        return true
      },
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    MediaPlanningCampaignIntegrationDialog,
  },
  data() {
    return {
      open_integration_dialog: false,
    }
  },
  methods: {
    toggle_dialog() {
      if (!this.enable_dialog) return
      this.open_integration_dialog = true
    },
    visit_invidi_campaign() {
      window.location.href = `/media_plannings/${this.media_planning.id}/invidi/${this.integration.code}`
    },
  },
  computed: {
    link_class() {
      if (this.enable_dialog) {
        return "media-planning-campaign-integration--link"
      }
    },
    invidi_link() {
      if (!this.enable_invidi_link) return
      return this.integration.platform_title === "Invidi"
    },
  },
}
</script>
