import "./media-plannings.scss"
import Vue from 'vue/dist/vue.esm'
import MediaPlanningsApp from './media-plannings-app.vue'
import ActionCableVue from 'actioncable-vue'

const websocket_protocol = `ws${window.location.protocol === "https:" ? "s" : ""}:`
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'info',
  connectionUrl: `${websocket_protocol}//${window.location.host}/cable`,
  connectImmediately: true
})

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("media-plannings"))
       .forEach((element) => {
         const media_plannings                          = JSON.parse(element.dataset.mediaPlannings)
         const channels                                 = JSON.parse(element.dataset.channels)
         const integrations                             = JSON.parse(element.dataset.integrations)
         const turnover_groups                          = JSON.parse(element.dataset.turnoverGroups);
         const google_ads_customers                     = JSON.parse(element.dataset.googleAdsCustomers)
         const users                                    = JSON.parse(element.dataset.users)
         const teams                                    = JSON.parse(element.dataset.teams)
         const customers                                = JSON.parse(element.dataset.customers)
         const advertisers                              = JSON.parse(element.dataset.advertisers)
         const filter_live                              = JSON.parse(element.dataset.filterLive)
         const filter_reporting                         = JSON.parse(element.dataset.filterReporting)
         const params                                   = new URLSearchParams(document.location.search)
         const original_manager_id                      = params.get("manager_id")
         const original_team_id                         = params.get("team_id")
         const original_customer_id                     = params.get("customer_id")
         const original_advertiser_id                   = params.get("advertiser_id")
         const original_turnover_group_id               = params.get("turnover_group_id")
         const original_channel_id                      = params.get("channel_id")
         const original_filter_soo_to_end               = params.get("filter_soo_to_end") ? 1 : 0
         const original_filter_missing_xv_order_id      = params.get("filter_missing_xv_order_id") ? 1 : 0
         const original_filter_weekly_reporting         = params.get("filter_weekly_reporting") ? 1 : 0
         const original_filter_detailed_final_reporting = params.get("filter_detailed_final_reporting") ? 1 : 0
         const original_filter_notes                    = params.get("filter_notes") ? 1 : 0
         const original_filter_tasks                    = params.get("filter_tasks") ? 1 : 0
         const can_manage                               = JSON.parse(element.dataset.canManage)
         new Vue({
                   data: {
                     media_plannings: media_plannings,
                     channels: channels,
                     integrations: integrations,
                     turnover_groups: turnover_groups,
                     google_ads_customers: google_ads_customers,
                     original_filter_live: filter_live,
                     original_filter_reporting: filter_reporting,
                     original_filter_soo_to_end,
                     original_filter_missing_xv_order_id,
                     original_filter_weekly_reporting,
                     original_filter_detailed_final_reporting,
                     original_filter_notes,
                     original_filter_tasks,
                     users,
                     teams,
                     customers,
                     advertisers,
                     original_manager_id,
                     original_team_id,
                     original_customer_id,
                     original_advertiser_id,
                     original_turnover_group_id,
                     original_channel_id,
                     can_manage,
                   },
                   template: "<MediaPlanningsApp :original_media_plannings='media_plannings' :channels='channels' :integrations='integrations' :turnover_groups='turnover_groups' :google_ads_customers='google_ads_customers' :users='users' :teams='teams' :customers='customers' :advertisers='advertisers' :original_manager_id='original_manager_id' :original_team_id='original_team_id' :original_customer_id='original_customer_id' :original_advertiser_id='original_advertiser_id' :original_turnover_group_id='original_turnover_group_id' :original_channel_id='original_channel_id' :original_filter_soo_to_end='original_filter_soo_to_end' :original_filter_missing_xv_order_id='original_filter_missing_xv_order_id' :original_filter_weekly_reporting='original_filter_weekly_reporting' :original_filter_detailed_final_reporting='original_filter_detailed_final_reporting' :original_filter_notes='original_filter_notes' :original_filter_tasks='original_filter_tasks' :original_filter_live='original_filter_live' :original_filter_reporting='original_filter_reporting' :can_manage='can_manage' />",
                   components: {
                     MediaPlanningsApp,
                   }
                 }).$mount(element)
       })
})
